// モジュールをインポート
// Modernizrの読み込み
import { modernizr } from "./util/modernizr";

// GETパラメータパーサー
import { ParceFormQuery } from "./form/ParseFormQuery";

// フォームバリデーター
import {
  FormValidationModule,
  Validator,
} from "./form/FormValidationModule";

// reCaptcha
import { ReCaptchaModule } from "./form/ReCaptchaModule";

/* ==========================================================================
 *
 * お問い合わせページjs
 *
========================================================================== */
/* エラーメッセージ出力関数をaatone用にオーバーライド。 ------------------------------------- */
class FormValidationModuleAtone extends FormValidationModule{
  _createErrorMessageArray(validity = {}) {
    const _self = this;

    // カスタムエラー文言を作成
    let validationMessage = Object.keys(validity)
      .map((key) => {
        // エラー内容があればメッセージに追加
        if (validity[key] === true && key !== "valid") {
          if (typeof _self.validator.message[key] === "undefined") {
            // 対応するエラーがなければデフォルトエラーを追加
            return _self.validator.message["valid"];
          } else {
            // 対応するエラーがあればエラーを追加
            return _self.validator.message[key];
          }
        }
      })
      // undefinedを削除
      .filter(Boolean);

    // DOMを作成
    var messageInner = document.createElement("p");
    messageInner.className = 'c-error-text';
    // 複数エラーを改行でjoin
    messageInner.innerHTML = validationMessage.join("<br>");

    var message = document.createElement("div");
    message.className = _self.ERROR_CLASS;
    message.append(messageInner);

    return message;
  }
}

class PardotFormIntegrator {
  /* 変数宣言 ------------------------------------- */
  defaultButtonHtml;
  // 離脱抑制を行うかどうかのフラグ
  leaveAlertFlug;

  // 現在のステップを保存
  currentStep;

  // DOM用メンバー
  formElm;
  formElms;

  submitElm;

  stepElms;
  nextButtons;
  backButtons;

  // インスタンス用メンバー
  ValidatorModule;
  FormValidationModule;
  ReCaptchaModule;
  ParceFormQuery;

  /**
   * コンストラクター
   */
  constructor() {
    // DOMの確保
    this.formElm = document.getElementById("form");
    this.formElms = document.querySelectorAll("*[data-valid-form]");

    this.submitElm = document.querySelector("*[data-valid-submit]");

    this.stepElms = document.querySelectorAll(".step");

    this.nextButtons = document.querySelectorAll("*[data-valid-next]");
    this.backButtons = document.querySelectorAll("*[data-valid-back]");

    this.initialize();
  }

  /**
   * フォーム管理クラスを初期化
   */
  initialize() {
    let _self = this;

    // 必須要素がない場合離脱
    if( this.formElm == null ) return;

    // フォームバリデーターを起動する
    this.ValidatorModule = new Validator({
      // エラーメッセージの変更
      message: {
        rangeOverflow: "文字数が多すぎます。",
        rangeUnderflow: "文字数が少すぎます。",
      },
    });

    // 各ステップに対してバリデータインスタンスを生成
    this.FormValidationModule = [];
    for (var i = 0, len = (this.formElms).length; i < len; i++) {
      this.FormValidationModule.push(
        new FormValidationModuleAtone(
          // 対象要素
          this.formElms[i],
          // オプション
          {
            errorClass: "p-form-item__error",
            displayErrorMessage: true,
            useSubmitLock: true,
          },
          // バリデーターインスタンス
          this.ValidatorModule
        )
      );
    }

    // reCaptcha問い合わせモジュールインスタンス生成
    this.ReCaptchaModule = new ReCaptchaModule({
      reCaptchaSiteKey: "6LfgiFsaAAAAAAvbEsguIyAiuDhKXqoliw_0AY1_",
      reCaptchaPhpFile: "/form/libs/isHuman.php",
    });


    // Pardotからの返送エラーの解析
    this.parceFormError();

    // UTMパラメータのHTMLへの挿入
    this.setInputUtmParams();

    // ステップ関連イベントの設置
    if (this.stepElms != null) this.setStepEvents();

    // サブミットボタンにイベントをセット
    if (this.submitElm != null) this.setSubmitEvents();

    // フォームから離れるユーザーへ警告を表示する
    this.leaveAlertFlug = false;
    this.formLeaveAlert();

    // ユーザーが一度でもフォームを触ったならば、ページ離脱警告フラグを立てる。
    (this.formElm).addEventListener("validate", () => {
      _self.leaveAlertFlug = true;
    });
  }

  /**
   * サブミットイベントをセット
   * @param -
   * @returns -
   */
  setSubmitEvents(){
    let _self = this;

    (_self.submitElm).addEventListener("click", () => {
      // 送信前バリデート
      (_self.FormValidationModule).forEach(formItem=>{
        formItem.getValidationResult();
      });

      // 自分にDisabledがついてないかを確認し、ついていれば送信を中止
      const submitArrow = _self.submitElm.getAttribute("data-valid-submit");
      if (submitArrow == "false") return;

      // ラベルの元の内容を保存
      _self.defaultButtonHtml = _self.submitElm.innerHTML;
      // ボタンのラベルを変更する。
      _self.submitElm.innerHTML = "<span>送信中</span>";

      // 現在表示しているエラーを全て除去する。
      _self.deleteMessage();

      // reCaptchaに判定結果を問い合わせる。
      new Promise((resolve, reject) => {
        // run() 実行で responseを得る
        let result = _self.ReCaptchaModule.run();

        if (result != null) {
          resolve(result);
        } else {
          reject("error");
        }
      }).then((_result) => {
        // 検証用結果確定
        // _result["isHuman"] = false;

        // botでなければそのままフォームを送信する。
        // memo: safari初回でreCaptchaエラーが起きるバグ対策にsafariのみ結果に関わらず素通しする。
        if (_result["isHuman"] == true || modernizr.browserDetection.safari() == true) {
          // 離脱抑制フラグをfalse
          _self.leaveAlertFlug = false;


          // バリデート完了要素を追加
          let validatorDom = document.createElement('input');
          validatorDom.setAttribute('id','validator');
          validatorDom.setAttribute('name','validator');
          validatorDom.setAttribute('type','hidden');
          validatorDom.setAttribute('value','1');
          (_self.formElm).appendChild(validatorDom);

          _self.formElm.submit();
        } else {
          // botの場合、ボタンを戻してエラー文言を出し、再度送信を要求する。
          _self.displayMessage("入力内容・通信環境をご確認のうえ、再度送信してください。");
          (_self.submitElm).innerHTML = _self.defaultButtonHtml;
        }
      })
      // 失敗した場合、エラーとして再度送信を要求する。
      .catch(() => {
        _self.displayMessage("入力内容・通信環境をご確認のうえ、再度送信してください。");
        (_self.submitElm).innerHTML = _self.defaultButtonHtml;
      });
    });
  }

  /* 離脱警告関連 ------------------------------------- */
  /**
   * フォーム入力中のページ移動に離脱警告を表示
   */
  formLeaveAlert() {
    let _self = this;

    (window).addEventListener("beforeunload", (event) => {
      // フラグが折れていれば離脱抑制はしない
      if( _self.leaveAlertFlug==false ) return;

      event = event || window.event;

      event.returnValue = "入力中のページから移動しますか？";
      return "入力中のページから移動しますか？";
    });
  }

  /* エラー関連 ------------------------------------- */
  /**
   * エラー文言を表示する。
   * @param text
   * @returns -
   */
   displayMessage(text = "") {
    if (this.formElm == null) return;

    // エラー文言を挿入
    if (text) {
      let message = document.createElement("p");
      message.classList.add('c-error-text','mb-30px','u-animation--active');
      message.innerHTML = text;

      this.formElm.prepend(message);
    }
  }

  /**
   * エラー文言を削除する。
   * @returns -
   */
  deleteMessage() {
    if (this.formElm == null) return;

    // メッセージが存在すれば削除する。
    let messages = this.formElm.querySelectorAll(".form__error");

    if (messages) {
      Object.keys(messages).forEach((element, i) => {
        if (element == '0') return;

        if (this.formElm && messages[i]) this.formElm.removeChild(messages[i]);
      });
    }
  }

  /**
   * Pardotからの返送エラーの解析とエラー表示を行う。
   * @returns -
   */
  parceFormError(){
    let _self = this;

    // Pardotから返送されたクエリーを展開
    this.ParceFormQuery = new ParceFormQuery();

    // GETパラメータをパースしフォームの内容を復帰
    if (this.ParceFormQuery.hasPardotError()) {
      // 復帰データでフォームをバリデート
      for (var i = 0, len = (this.FormValidationModule).length; i < len; i++) {
        // Pardotからエラーで戻ったときは全ステップバリデーションを実行
        this.FormValidationModule[i].getValidationResult();
      }

      // エラー文言を表示
      if( typeof this.ParceFormQuery.queryStrings.refferrer=='string' ){
        // リファラー項目があった場合、他ページでのバリデート不備で飛ばされてきたものと見なす。
        this.displayMessage("入力内容に不備があったため、再入力ページに移動しました。各項目をご確認のうえ、改めて送信してください。");
      }else{
        this.displayMessage("入力内容・通信環境をご確認のうえ、再度送信してください。");
      }
    }
  }


  /* ステップ関連 ------------------------------------- */
  /**
   * ステップ関連のイベント設置
   * @returns -
   */
  setStepEvents() {
    let _self = this;

    // 最初のステップをアクティブ化
    _self.updateStep(0);

    // ネクストボタンにイベントをセット
    (_self.nextButtons).forEach(function(element){
      (element).addEventListener("click", (e)=>{
        e.preventDefault();

        let result;

        // 送信前バリデート
        (_self.FormValidationModule).forEach(formItem=>{
          // 現在のステップを取得
          let targetStep = Number(formItem.form.getAttribute('data-valid-step'));

          // ループの現在の要素が現在のステップと一致していれば処理を進める
          if(targetStep == _self.currentStep){
            formItem.getValidationResult();

            result = formItem.state;
          }
        });

        // 自分にDisabledがついてないかを確認し、ついていれば離脱
        if (result == false) return;

        // ステップを一つ進める
        _self.updateStep( _self.currentStep+1 );
      }, false);
    });

    // バックボタンにイベントをセット
    (_self.backButtons).forEach(function(element){
      // 戻るボタンにイベントをセット
      (element).addEventListener("click", (e)=>{
        e.preventDefault();

        // ステップを一つ戻す
        _self.updateStep( _self.currentStep-1 );
      });
    });
  }

  /**
   * ステップ進捗を制御
   * @returns -
   */
  updateStep(step) {
    let _self = this;

    if (typeof step !== 'number') return;
    // 必須要素がない場合離脱
    if ( this.stepElms.length == 0 ) return;

    // 1以下であれば1に補正
    if (step<1) step = 1;

    _self.currentStep = step;

    // 該当ステップをアクティブ化する
    // 全要素を非アクティブ化
    (_self.stepElms).forEach(element=>{
      // cssで対応する場合
      element.classList.remove('active');
    });

    // 該当要素のみアクティブ化
    (_self.stepElms).forEach(element=>{
      if( element.getAttribute('data-valid-step') == _self.currentStep ){
        // cssで対応する場合
        element.classList.add('active');
      }
    });

    // ステップインジケーターを更新する
    let indicator_items = document.querySelectorAll(".p-form-step__step");
    // 必須要素がない場合離脱
    if( indicator_items.length == 0 ) return;

    // 全要素を非アクティブ化
    (indicator_items).forEach(function(element){
      element.classList.remove('p-form-step__step--current');
    });
    // 該当要素のみアクティブ化
    (indicator_items)[this.currentStep-1].classList.add('p-form-step__step--current');
  }

  /* GA＋Pardot連携関連 ------------------------------------- */
  /**
   * GAパラメータを非公開inputへ追加
   */
  setInputUtmParams(){
    let _self = this;

    // 項目作成対象パラメータを配列で定義
    const utm_params = [
      'utm_source','utm_campaign','utm_medium','utm_content','utm_term'
    ];

    // クエリーを処理
    const search = location.search;

    //クエリーがなければskip
    if (search.length == 0) return;

    // クエリストリングをパース
    const queryStrings = search
      .split("?")[1]
      .split("&")
      .map((p) => p.split("="))
        .reduce((obj, e) => ({ ...obj, [e[0]]: e[1] }), {});

    // GETパラメータの存在を確認し、存在していればhidden項目としてHTMLへ追加
    utm_params.forEach((item)=>{
      if( item in queryStrings ){
        // バリデート完了要素を追加
        let validatorDom = document.createElement('input');
        validatorDom.setAttribute('name',item);
        validatorDom.setAttribute('type','hidden');
        validatorDom.setAttribute('value',queryStrings[item]);
        (_self.formElm).appendChild(validatorDom);
      }
    });
  }
}

window.addEventListener("load", () => {
  // インスタンスを生成
  new PardotFormIntegrator();
});
